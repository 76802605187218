
import { defineComponent, inject, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Params } from '@/store/todo/types'
import { todoKey } from '@/store/todo'

export default defineComponent({
  setup () {
    const todoStore = inject(todoKey)
    if (!todoStore) {
      throw new Error('todoStore is not provided')
    }

    const router = useRouter()
    const route = useRoute()

    const id = Number(route.params.id)
    try {
      const todo = todoStore.getTodo(id)

      const data = reactive<Params>({

        title: todo.title,
        description: todo.description,
        status: todo.status
      })

      const onSubmit = () => {
        const { title, description, status } = data
        todoStore.updateTodo(id, {

          ...todo,
          title,
          description,
          status
        })
        router.push('/')
      }

      return {
        error: false,
        data,
        onSubmit
      }
    } catch (e) {
      console.error(e)
      return {
        error: true
      }
    }
  }
})
