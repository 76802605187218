import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_todo_item = _resolveComponent("todo-item")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.todoStore.state.todos, (todo) => {
      return (_openBlock(), _createBlock(_component_todo_item, {
        key: todo.id,
        todo: todo,
        onClickTitle: _ctx.clickTitle,
        onClickDelete: _ctx.clickDelete
      }, null, 8, ["todo", "onClickTitle", "onClickDelete"]))
    }), 128))
  ]))
}